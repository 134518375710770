import React, { FC, MutableRefObject } from 'react';
import Modal from "react-bootstrap/Modal";
import QrReader from 'react-qr-reader'
import { Events, ScanFrom } from "../../constants";
import Emitter from "../../helpers/emitter";
import { redirectToNewURL, setPOIFromQRCode } from "../../helpers/marker";

export interface IQRScanner {
    changeLoading?: (loading: boolean) => void;
}

const QRScanner: FC<IQRScanner> = () => {
    const cleanupFunction: MutableRefObject<any> = React.useRef(false);
    const [show, setShow] = React.useState(false);
    const [scanFrom, setScanFrom] = React.useState(0);
    const scanned: any = React.useRef(false);

    const onClose = React.useCallback(() => {
        setShow(false);
    }, [setShow]);

    const handleError = React.useCallback(() => {
        setShow(false);
    }, []);

    const handleScan = React.useCallback((result: string | null) => {
        if (result && !scanned.current) {
            scanned.current = true;
            const readerBox: any = document.querySelector('.qr-reader');
            readerBox.classList.add('qr-detected');

            setTimeout(() => {
                if(scanFrom === ScanFrom.SEARCH) {
                    redirectToNewURL(result);
                }
                if(scanFrom === ScanFrom.NAV) {
                    setShow(false);
                    setPOIFromQRCode(result);
                }
            }, 100);
        }
    }, [scanFrom]);

    React.useEffect(() => {
        cleanupFunction.current = false;
        scanned.current = false;

        Emitter.once(Events.OPEN_QR_SCANNER, ({ isShow, from }) => {
            setShow(isShow);
            setScanFrom(from);

            $(document).ready(() => {
                const readerBox: any = document.querySelector('.qr-reader section');
                $(readerBox).append('<div class="custom-scanner"></div>');
            });
        });

        return () => {
            cleanupFunction.current = true;
        };
    }, [
        setShow,
        show,
        cleanupFunction,
        setScanFrom
    ]);

    return (
        <Modal show={show} animation={true} centered id="qr_scanner" onHide={() => false } className="qr-scanner-modal">
            <Modal.Body className="modal-width">
                <div className="campus-modal">
                    <div className="window-header">
                        <div className="window-header-left">
                            <button className="back-btn pull-left"
                                    type="button"
                                    onClick={onClose}
                            >
                                <i className="fas fa-arrow-left" />
                            </button>
                            <h4>{(window as any).lang.getString('QRScanner_QRScan')}</h4>
                        </div>
                    </div>
                    <div className="window-content">
                        <QrReader
                            delay={100}
                            onError={handleError}
                            onScan={handleScan}
                            className="qr-reader"
                        />
                        <p>
                            {(window as any).lang.getString('QRScanner_bringTheCamera')}
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default QRScanner;
